import { Modal, Form, Input,Select ,Button,message,Row,Col} from 'antd';
import React,{Component} from 'react';
import { PasswordCheck, MacInput,LabelInput,LabelSelect } from 'libs';
import {
    addUser,
    updateAddLoginLimitData,
    setLoginLimit
} from '../../../store/views/UgmSet/action';
import { connect } from 'react-redux';
import AuthSet from '../AuthSetting';
import Moment from 'moment';
import {transformMacToString} from '../transform';
import { lengthB } from 'utils/checkbit';
import { Check } from 'utils/commonCheck';
const { Option } = Select;

const mapStateToProps = (state) => {
    let { UgmSet, publicState } = state;
    return { UgmSet, publicState };
};
// const mapDispatchToProps = {
//     addUser,
//     updateAddLoginLimitData
// };

const mapDispatchToProps = dispatch => {
    return {
        addUser: (data) => {dispatch(addUser(data));},
        updateAddLoginLimitData: (data) => {dispatch(updateAddLoginLimitData(data));},
        setLoginLimit: (data) => {dispatch(setLoginLimit(data));},
    };
};

const addUserForm = Form.create({ name: 'add_user_form' })(
    class extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                visible:false,
                AuthorityList:[],
                ValidAuth:[]
            };
        }
        // modal开关
        changeVisibleStatus = () => {
            this.resetAuthTab();
            this.setState(({visible}) => ({
                visible:!visible
            }));
        }
        // 重置auth 的 tab
        resetAuthTab = () => {
            this.authRef && this.authRef.setDefaultKey();
        }
        // 用户名不重复
        noReaptName = (rule, value, callback) => {
            let {UgmSet,$t} = this.props;
            if (UgmSet.users.map(v => v.Name).indexOf(value) !== -1) {
                callback($t('com.UserExist'));
            } else if (!Check.checkReg('userName',value)) {
                callback($t('com.UserNameHasForbiddenCharacter'));
            } else {
                callback();
            }
        };
        //备注不超过32位,不使用非法字符< 和 >
        memoValidator = (rule, value, callback) => {
            let {$t} = this.props;
            if (!value) {
                callback();
            } else if (value.length > 32) {
                callback($t('com.memoLimitTip'));
            } else if (!Check.checkReg('remarkValid',value)) {
                callback($t('sys.BadMemoTip'));
            } else {
                callback();
            }
        };
        // 校验特殊格式的ipv6
        checkIPV6 = (val) => {
            let reg = /(^(::)$)|(^(:(:[0]{1,4}){1,7})$)|(^(([0]{1,4}:){1,7}([0]{1,4}))$)|(^(ff[a-f\d]{2}))/;
            return !reg.test(val);
        }
        ipv6ornull = (str) => {
            return str === '' || Check.checkReg('ipv6', str);
        }
        // 确认增加用户
        handleConfirmAdd = () => {
            let {
                form,fetchData,addUser,$t,
                setLoginLimit,
                updateAddLoginLimitData,
                UgmSet:{
                    users,
                    addLoginLimitData,
                    LoginRestriction
                },
                publicState:{userName},
                AuthUserMag
            } = this.props;
            let loginRestrictionTmp = [...LoginRestriction];
            let addLoginLimitDataTmp = {...addLoginLimitData};

            form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                let passwordCheckResult = this.PasswordRef.onSubmit();
                // let macCheckResult = this.MacRef.onSubmit();
                if (!passwordCheckResult.result) {
                    return;
                }
                if (this.authRef && this.authRef.state.currentIPType === 'IPv6') {
                    let IPv6AddressTmp = addLoginLimitDataTmp.AllowedIP.IP;
                    if (this.authRef.state.currentIPMode === 'IPSegment' && IPv6AddressTmp.indexOf('-') !== -1) { //IPv6网段
                        let ip = IPv6AddressTmp.split('-')[0];
                        let ip2 = IPv6AddressTmp.split('-')[1];
                        if(!(this.ipv6ornull(ip) && this.checkIPV6(ip) && this.ipv6ornull(ip2) && this.checkIPV6(ip2))) {
                            message.error($t('com.IPv6FormatError'));
                            return;
                        }
                    }else if (!(this.ipv6ornull(IPv6AddressTmp) && this.checkIPV6(IPv6AddressTmp))) {// IPv6地址
                        message.error($t('com.IPv6FormatError'));
                        return;
                    }
                }
                if ( passwordCheckResult.newPassword.length > 32) {
                    message.error($t('com.PasswordLength'));
                    return;
                }

                let startTime = Moment(addLoginLimitDataTmp.AllowdLifeTime.LifeTimeStart);
                let endTime = Moment(addLoginLimitDataTmp.AllowdLifeTime.LifeTimeEnd);
                if (startTime.valueOf() >= endTime.valueOf()) {
                    message.error($t('com.BeginTLessThanEndTTip'));
                    return;
                }
                // if (macCheckResult.code && macCheckResult.code === -1) {
                //     message.error($t('com.CpDDNS902Tip'));
                //     return;
                // }
                if (this.state.AuthorityList.length === 0) {
                    message.error($t('sys.Noauthmoderror'));
                    return;
                }
                let userObj = {
                    Id: users.length + 1,
                    Name: values.Name,
                    Password:passwordCheckResult.newPassword,
                    //MacOnly:transformMacToString(macCheckResult),
                    Memo: values.Memo,// 备注
                    Sharable: true,
                    Group: values.Group,
                    AuthorityList:this.state.AuthorityList
                };
                //登录限制数据
                addUser({
                    params:userObj,
                    success:() => {
                        if(userName === 'admin') {
                            addLoginLimitDataTmp.UserName = values.Name;
                            loginRestrictionTmp.push(addLoginLimitDataTmp);
                            setLoginLimit({
                                loginLimitArr: loginRestrictionTmp,
                                success:() => {
                                    fetchData();
                                    this.changeVisibleStatus();
                                    form.resetFields();
                                    message.success($t('com.Operateingsuccess'));
                                },
                                error: () => {
                                    fetchData();
                                    this.changeVisibleStatus();
                                    form.resetFields();
                                    message.error($t('com.AddUserSuccessButAuthFial'));
                                }
                            });
                        } else {
                            fetchData();
                            this.changeVisibleStatus();
                            form.resetFields();
                            message.success($t('com.Operateingsuccess'));
                        }
                    },
                    error:(errorText) => {
                        message.error($t(errorText));
                    }
                } );
            });
        }
        getLoginLimitData = () => {
            let {LoginRestriction} = {...this.props};

            var result = {
                AllowedIP: {
                    Enable: false,
                    IP: ''
                },
                AllowedTimeSchedule: {
                    Enable: false,
                    TimeSchedule: ''
                },
                AllowdLifeTime: {
                    Enable: false,
                    LifeTimeStart: '',
                    LifeTimeEnd: ''
                }
            };

            return LoginRestriction;
        }
        // 选择的group改变，权限相应重置
        changeGroup = (v) => {
            const {UgmSet, publicState:{userName}} = this.props;
            const {Group, users} = UgmSet;
            let AuthorityList = [];
            Group.forEach(item => {
                if (item.Name === v) AuthorityList = item.AuthorityList;
            });//当前用户组的权限列表
            let curUser = users.filter((item, index) => {
                return item.Name === userName;
            })[0];
            let curUsernameAuthList = curUser && curUser.AuthorityList || []; //当前用户的权限列表

            //找出两个数组中的交集元素arr1.filter(v => arr2.includes(v));
            AuthorityList = curUsernameAuthList.filter(v => AuthorityList.includes(v));

            this.setValidAuthorityList(AuthorityList);
            this.updateUserAuth(AuthorityList);
        }
        // 受控权限
        updateUserAuth= (AuthorityList) => {
            this.setState({
                AuthorityList
            });
        }
        // 改变可用权限
        setValidAuthorityList = (AuthorityList) => {
            this.setState({
                ValidAuth:AuthorityList
            });
        }
        getDefaultUserGroup = () => {
            const {Group} = this.props.UgmSet;
            if (Group.length) {
                return Group[0].Name;
            }else {
                return '';
            }
        }
        render () {
            const { form ,$t, UgmSet,commonModalParam,commonFormItemLayout, publicState:{userName},publicState:{getGrid}} = this.props;
            const { getFieldDecorator } = form;
            const multiple = {
                type:'normal',
                multiple:1
            };
            const diaLogFooter = [
                <Button key="submit" type="primary" loading={false} onClick={this.handleConfirmAdd}>
                    {$t('com.Apply')}
                </Button>,
                <Button key="back" onClick = {this.changeVisibleStatus}>
                    {$t('com.Cancel')}
                </Button>];
            const form1ItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 0 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                }
            };
            return (
                <Modal
                    {...commonModalParam}
                    visible={this.state.visible}
                    title={$t('com.Add')}
                    onCancel={this.changeVisibleStatus}
                    footer = {diaLogFooter}
                    width= {'800px'}
                >
                    <Form hideRequiredMark {...commonFormItemLayout} labelAlign='left' colon={false} className='addFormItem'>
                        <Form.Item style={{marginBottom: 0}}>
                            {getFieldDecorator('Name', {
                                rules: [
                                    { required: true, message: $t('com.Necessary') },
                                    {
                                        validator: this.noReaptName,
                                    }
                                ],
                            })(<LabelInput label={$t('com.Username')} colWidth = {[getGrid(multiple).label,{span:16},{span: 1}]} maxLength={31}/>)}
                        </Form.Item>
                        <Form.Item style={{marginBottom: 4}}>
                            <PasswordCheck colWidth = {[getGrid(multiple).label,{span:16},{span: 1}]} ref={(v) => this.PasswordRef = v} addUserPwdLabel = {true}/>
                        </Form.Item>
                        <Form.Item style={{marginBottom: 8}}>
                            {getFieldDecorator('Group', {
                                initialValue:this.getDefaultUserGroup(),
                            })(
                                <LabelSelect label={$t('sys.UserGroup')} colWidth = {[getGrid(multiple).label,{span:16},{span: 1}]} className='groupSelection' onChange={this.changeGroup}>
                                    {
                                        UgmSet.Group.map((v,i) => {
                                            return <Option key={i} value={v.Name}>{v.Name}</Option>;
                                        })
                                    }
                                </LabelSelect>
                            )}
                        </Form.Item>
                        {/* <div className='macContainer'>
                            <MacInput
                                ref={(v) => this.MacRef = v} controlWidth={[3, 18]} mac={''} emptyEnable = {true}/>
                        </div> */}
                        <Form.Item >
                            {getFieldDecorator('Memo', {
                                rules:[
                                    {
                                        validator: this.memoValidator
                                    }
                                ],
                                normalize: (value, prevValue) => {
                                    if (value && lengthB(value) > 31) {
                                        return prevValue;
                                    } else {
                                        return value;
                                    }
                                }
                            })(
                                <LabelInput label={$t('com.Remark')} colWidth = {[getGrid(multiple).label,{span:16},{span: 1}]} maxLength={31}/>
                            )}
                        </Form.Item>
                        <AuthSet
                            $t={$t}
                            defaultAuth={UgmSet.defaultAuth}
                            AuthorityList={this.state.AuthorityList}
                            updateAuth={this.updateUserAuth}
                            ValidAuth = {this.state.ValidAuth}
                            ref = {(v) => this.authRef = v}
                            supportLoginLimit = {userName === 'admin'}
                            operationType = {'addUser'}
                        ></AuthSet>
                    </Form>
                </Modal>
            );
        }
    },
);
export const AddUserForm = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(addUserForm);